import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import * as deployEnvironment from '../../../aawaas-infra/outputs.json';

export default async () => {
  /**
   * The code to be executed should be placed within a default function that is
   * exported by the global script. Ensure all of the code in the global script
   * is wrapped in the function() that is exported.
   */
};

export const DEFAULT_NOTCH_COLOR = '#00101D' as const;

export const SECRET_KEY: string = 'secretKey';

export enum IdentityProviderName {
  GOOGLE = 'Google',
}

export const infraStackEnvironment = getValueWithKeySuffixMatch(deployEnvironment['default'], `AAWaaSInfraStack`);

export const oauthConfig = {
  domain: 'https://seabw-test.auth.ap-northeast-2.amazoncognito.com',
  userPoolId: getValueWithKeyPrefixMatch(infraStackEnvironment, 'UserPoolUserPoolId'),
  userPoolClientId: getValueWithKeyPrefixMatch(infraStackEnvironment, 'UserPoolUserPoolClientId'),
};

export const getCognitoUserPool = () => {
  const poolData = {
    UserPoolId: oauthConfig.userPoolId,
    ClientId: oauthConfig.userPoolClientId,
  };

  return new AWSCognitoIdentity.CognitoUserPool(poolData);
};

export const getCognitoUser = ({ userName }: { userName: string }) => {
  const userData = {
    Username: userName,
    Pool: getCognitoUserPool(),
  };
  return new AWSCognitoIdentity.CognitoUser(userData);
};

export const homeURL = (() => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000';
  }

  const optionalAlternativeAccessHost = getValueWithKeyPrefixMatch(infraStackEnvironment, 'HostNameOptionalAlternativeAccessHost');

  if (optionalAlternativeAccessHost && optionalAlternativeAccessHost.length > 0) {
    return `https://${optionalAlternativeAccessHost}`;
  }

  return `https://${getValueWithKeyPrefixMatch(infraStackEnvironment, 'HostNameBaseAccessHost')}`;
})();

export const redirectTOTP = `${homeURL}/totp`;

export function getValueWithKeyPrefixMatch(jsonData: any, keyPrefix: string) {
  for (const key in jsonData) {
    if (key.startsWith(keyPrefix)) {
      return jsonData[key];
    }
  }
  throw new Error(`${keyPrefix} prefix not found in JSON`);
}

export function getValueWithKeySuffixMatch(jsonData: any, partialKey: string) {
  for (const key in jsonData) {
    if (key.endsWith(partialKey)) {
      return jsonData[key];
    }
  }
  throw new Error(`${partialKey} suffix not found in JSON`);
}

export function setNotchColor(color: string) {
  const metaElements = document.getElementsByTagName('meta');
  const metaElementsCount = metaElements.length;

  for (let i = 0; i < metaElementsCount; i++) {
    const element = metaElements[i];
    if (element.name !== 'theme-color') continue;

    element.content = color;
    break;
  }
}
